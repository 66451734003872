





























import { Office } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class OfficeCard extends Vue {
  @Prop()
  private readonly office!: Office;
}
