































import { Component, Vue, Prop } from 'vue-property-decorator';

import { ImageSourceSet } from 'client-website-ts-library/types';

@Component
export default class Awards extends Vue {}
